<div class="container">
  <div class="section">
    <div class="row mb-5">
      <div class="col-12 col-md-8 col-xl-6">
        <h3 class="staggered-icons__header">{{ heading }}</h3>
        <p class="staggered-icons__subheader">
          {{ subheading }}
        </p>
      </div>
    </div>

    <div class="staggered-icons__items-container">
      <div class="row">
        <div
          *ngFor="let item of items; let i = index"
          class="col-12 col-md-6"
          [ngClass]="{ 'staggered-icons__col1': i % 2 === 0, 'mb-5 mb-md-0': i !== items.length - 1 }"
        >
          <div class="staggered-icons__item-container">
            <div>
              <img [src]="item.image" />
            </div>
            <label class="staggered-icons__item-title">{{ item.title }}</label>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
