<div class="footer__background">
  <div>
    <img class="footer__mobile-mesh" src="assets/backgrounds/mesh-footer-mobile.png" />
  </div>

  <div class="footer__desktop-mesh">
    <div class="container">
      <div class="footer__section1 row">
        <div class="col-12 col-lg-7">
          <h3 class="footer__header">{{ isPrimaryContent ? contentfulService.footer.header1 : contentfulService.footer.header2 }}</h3>
          <p class="footer__subheader">{{ isPrimaryContent ? contentfulService.footer.subHeader1 : contentfulService.footer.subHeader2 }}</p>
        </div>
        <div class="col-12 col-lg-5">
          <div class="row">
            <div class="col-12 col-sm-10 col-md-7 mt-4 mt-lg-0">
              <button class="button__primary d-flex justify-content-between" (click)="openAccessModal()">
                {{contentfulService.footer.accessBtn}} <img src="assets/icons/arrow-diagonal-black.png" />
              </button>
            </div>
            <div class="col-12 col-sm-8 col-md-5 mt-4 mt-lg-0">
              <button *ngIf="!isOnFaq" class="button__secondary d-flex justify-content-between" routerLink="/company/faq">
                {{contentfulService.footer.faqButton}} <img src="assets/icons/arrow-diagonal-maroon.png" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Desktop view -->
      <div class="d-none d-md-block">
        <div class="row">
          <div class="col-5">
            <div>
              <label class="footer__label">{{contentfulService.footer.followUsBtn}}</label>
              <a href="https://www.youtube.com/channel/UC-tikvziELaxX6lWmcVcGvA"><img src="assets/icons/social-youtube.png" /></a>
              <a href="https://www.linkedin.com/company/mantle-technology/posts/?feedView=all"><img class="ml-3 mr-3" src="assets/icons/social-linkedin.png" /></a>
              <a href="https://github.com/mantle-labs"><img src="assets/icons/social-github.png" /></a>
            </div>

            <div class="footer__logo-container">
              <img src="assets/icons/logo-maroon.png" />
              <p>{{contentfulService.footer.address}}</p>
              <p>{{contentfulService.footer.phone}} <a class="footer__email" href="mailto:someone@yoursite.com">{{contentfulService.footer.email}}</a></p>
            </div>
          </div>

          <div class="col-7 d-flex justify-content-between">
            <!-- PLATFORM SUB-MENU -->
            <div>
              <label class="footer__label">{{contentfulService.footer.platformSubMenuTitle}}</label>
              <ul [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
                <li class="mb-4" routerLink="/platform/what-is-mantle">{{contentfulService.footer.platformSubMenuAbout}}</li>
                <li class="mb-4" routerLink="/platform/what-is-mantle">{{contentfulService.footer.platformSubMenuDifference}}</li>
                <li routerLink="/platform/pricing">{{contentfulService.footer.platformSubMenuPricing}}</li>
              </ul>
            </div>

            <!-- DEVELOPERS SUB-MENU -->
            <div>
              <label class="footer__label">{{contentfulService.footer.developersSubMenuTitle}}</label>
              <ul [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
                <li class="mb-4">
                  <a class="footer__link" href="https://developer.mantleblockchain.com/docs/getting-started">{{contentfulService.footer.developersSubMenuQuickstart}}</a>
                </li>
                <li class="mb-4"><a class="footer__link" href="https://developer.mantleblockchain.com/docs/getting-started">{{contentfulService.footer.developersSubMenuDoc}}</a></li>
              </ul>
            </div>

            <!-- COMPANY SUB-MENU -->
            <div>
              <label class="footer__label">{{contentfulService.footer.companySubMenuTitle}}</label>
              <ul *ngIf="isDevMode" [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
                <li class="mb-4" routerLink="/company/about">{{contentfulService.footer.companySubMenuAbout}}</li>
                <li class="mb-4" routerLink="/company/careers">{{contentfulService.footer.companySubMenuCareers}}</li>
                <li class="mb-4" routerLink="/company/faq">{{contentfulService.footer.companySubMenuFaq}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <div class="d-block d-md-none">
      <ngb-accordion #footerAccordion closeOthers="true">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span class="acc-title">{{contentfulService.footer.followUsBtn}}</span>
            <div>
              <a href="https://www.youtube.com/channel/UC-tikvziELaxX6lWmcVcGvA"><img src="assets/icons/social-youtube.png" /></a>
              <a href="https://www.linkedin.com/company/mantle-technology/posts/?feedView=all"><img class="ml-3 mr-3" src="assets/icons/social-linkedin.png" /></a>
              <a href="https://github.com/mantle-labs"><img src="assets/icons/social-github.png" /></a>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- PLATFORM SUB-MENU -->
        <ngb-panel>
          <ng-template ngbPanelTitle
            ><span class="acc-title">{{contentfulService.footer.platformSubMenuTitle}}</span><img src="assets/icons/arrow-down-maroon.png"
          /></ng-template>
          <ng-template ngbPanelContent>
            <ul [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
              <li class="mb-2" (click)="onClick('/platform/what-is-mantle')">{{contentfulService.footer.platformSubMenuAbout}}</li>
              <li class="mb-2" (click)="onClick('/platform/what-is-mantle')">{{contentfulService.footer.platformSubMenuDifference}}</li>
              <li class="footer__item" (click)="onClick('/platform/pricing')">{{contentfulService.footer.platformSubMenuPricing}}</li>
            </ul>
          </ng-template>
        </ngb-panel>

        <!-- DEVELOPERS SUB-MENU -->
        <ngb-panel>
          <ng-template ngbPanelTitle
            ><span class="acc-title">{{contentfulService.footer.developersSubMenuTitle}}</span><img src="assets/icons/arrow-down-maroon.png"
          /></ng-template>
          <ng-template ngbPanelContent>
            <ul [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
              <li class="mb-2">
                <a class="footer__link" href="https://developer.mantleblockchain.com/docs/new-to-mantle">{{contentfulService.footer.developersSubMenuQuickstart}}</a>
              </li>
              <li class="mb-2"><a class="footer__link" href="https://develop.api.mantle.technology/api-docs">{{contentfulService.footer.developersSubMenuDoc}}</a></li>
            </ul>
          </ng-template>
        </ngb-panel>

        <!-- COMPANY SUB-MENU -->
        <ngb-panel *ngIf="isDevMode">
          <ng-template ngbPanelTitle
            ><span class="acc-title">{{contentfulService.footer.companySubMenuTitle}}</span><img src="assets/icons/arrow-down-maroon.png"
          /></ng-template>
          <ng-template ngbPanelContent>
            <ul [ngClass]="frenchSet ? 'footer__item-french' : 'footer__item'">
              <li class="mb-2" (click)="onClick('/company/about')">{{contentfulService.footer.companySubMenuAbout}}</li>
              <li class="mb-2" (click)="onClick('/company/careers')">{{contentfulService.footer.companySubMenuCareers}}</li>
              <li class="mb-2" (click)="onClick('/company/faq')">{{contentfulService.footer.companySubMenuFaq}}</li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      
      <div class="footer__logo-container container">
        <img src="assets/icons/logo-maroon.png" />
        <p>{{contentfulService.footer.address}}</p>
        <p>{{contentfulService.footer.phone}} <a class="footer__email" href="mailto:someone@yoursite.com">{{contentfulService.footer.email}}</a></p>
      </div>
    </div>

    <div class="container">
      <p class="footer__footnote">
        {{contentfulService.footer.legalTitle}} |<span class="footer__pointer" routerLink="/legal/privacy-policy"> {{contentfulService.footer.privacyTerms}}</span> |
        <span class="footer__pointer" routerLink="/legal/terms">{{contentfulService.footer.termsAndConditions}}</span>
      </p>
    </div>
  </div>
</div>