<div class="row">
  <div class="col-12 col-lg-7 col-xl-6">
    <div class="case-section__content-container">
      <label *ngIf="headingLabel" class="case-section__heading-label">{{ headingLabel }}</label>
      <h3 class="case-section__heading">{{ heading }}</h3>
      <p *ngIf="subheading" class="case-section__subheading">
        {{ subheading }}
      </p>
      <ul *ngIf="list" class="case-section__list">
        <li *ngFor="let item of list" class="case-section__list-item">{{ item }}</li>
      </ul>
    </div>
  </div>
  <div class="case-section__image-container">
    <img class="case-section__image" [src]="image" />
  </div>
</div>
