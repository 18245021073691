import { Injectable } from '@angular/core';
import { createClient } from 'contentful';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BehaviorSubject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

/*
  Contentful State Store Service -
  Any page that requires a specific content type will first check if the content type exists as an observable
  in the state store and otherwise fetch the content type directly from contentful
*/
const CONFIG = {
  space: '7pp8bgnb5ioi',
  accessToken: 'A-OfyA6FOxPTeq_gNOrLMxixbj_oAAP3xsA95nSzCj8',
  environment: 'local',
};
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => `<span class="contentful--bold">${text}</span>`,
    [MARKS.ITALIC]: (text) => `<span class="contentful--italic">${text}</span>`,
    [MARKS.UNDERLINE]: (text) => `<span class="contentful--underline">${text}</span>`,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, next) => `<h2 class="contentful__header">${next(node.content)}</h2>`,
    [BLOCKS.HEADING_6]: (node, next) => `<p class="contentful__paragraph--large">${next(node.content)}</p>`,
    [BLOCKS.QUOTE]: (node, next) => `<blockquote class="contentful__blockquote">${next(node.content)}</blockquote>`,
    [BLOCKS.PARAGRAPH]: (node, next) => `<p class="contentful__paragraph--small">${next(node.content)}</p>`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {

  private client = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
    environment: CONFIG.environment,
  });
  
  private readonly _environmentVariables = new BehaviorSubject<any>([]);
  private readonly _faqs = new BehaviorSubject<any[]>([]);
  private readonly _milestones = new BehaviorSubject<any[]>([]);
  private readonly _team = new BehaviorSubject<any[]>([]);
  private readonly _blog = new BehaviorSubject<any[]>([]);
  private readonly _singleImageHeaders = new BehaviorSubject<any[]>([]);
  private readonly _trustedCompanies = new BehaviorSubject<any[]>([]);
  private readonly _posters = new BehaviorSubject<any[]>([]);

  // Navigation
  public navbar: any = {};
  public platformModal: any = {};
  public developersModal: any = {};
  public footer: any = {};

  // Root Page
  public homePage: any = {};

  // Company
  public aboutPage: any = {};
  public careersPage: any = {};
  public contactUsPage: any = {};
  public faqPage: any = {};

  // Developers
  public articlePage: any = {};
  public developerProgramPage: any = {};
  public insightsPage: any = {};
  public partnerProgramPage: any = {};
  public supportedNetworksPage: any = {};

  // Legal
  public privacyPolicyPage: any = {};
  public termsPage: any = {};

  // Platform
  public whatIsMantlePage: any = {};
  public privacyPage: any = {};
  public crossCloudStoragePage: any = {};
  public pricingPage: any = {};
  public advancedCyberSecurityPage: any = {};
  public futureProofPage: any = {};
  public privacyAndDataCompliancePage: any = {};

  // Misc
  public contactForm: any = {};
  public requestAccessForm: any = {};

  readonly environmentVariables$ = this._environmentVariables.asObservable();
  readonly faqs$ = this._faqs.asObservable();
  readonly milestones$ = this._milestones.asObservable();
  readonly team$ = this._team.asObservable();
  readonly blog$ = this._blog.asObservable();
  readonly singleImageHeaders$ = this._singleImageHeaders.asObservable();
  readonly trustedCompanies$ = this._trustedCompanies.asObservable();
  readonly posters$ = this._posters.asObservable();

  private get environmentVariables(): any {
    return this._environmentVariables.getValue();
  }
  private set environmentVariables(val: any) {
    this._environmentVariables.next(val);
  }
  private get faqs(): any[] {
    return this._faqs.getValue();
  }
  private set faqs(val: any[]) {
    this._faqs.next(val);
  }
  private get milestones(): any[] {
    return this._milestones.getValue();
  }
  private set milestones(val: any[]) {
    this._milestones.next(val);
  }
  private get team(): any[] {
    return this._team.getValue();
  }
  private set team(val: any[]) {
    this._team.next(val);
  }
  private get blog(): any[] {
    return this._blog.getValue();
  }
  private set blog(val: any[]) {
    this._blog.next(val);
  }
  private get singleImageHeaders(): any[] {
    return this._singleImageHeaders.getValue();
  }
  private set singleImageHeaders(val: any[]) {
    this._singleImageHeaders.next(val);
  }
  private get trustedCompanies(): any[] {
    return this._trustedCompanies.getValue();
  }
  private set trustedCompanies(val: any[]) {
    this._trustedCompanies.next(val);
  }
  private get posters(): any[] {
    return this._posters.getValue();
  }
  private set posters(val: any[]) {
    this._posters.next(val);
  }

  constructor() {}

  /*
  Available Languages:
  English US    :   en-US (DEFAULT)
  French France :   fr-FR
  */
  changeLanguageToEnglish() {
    localStorage.setItem("locale", "en-US");
    window.location.reload();
  }

  changeLanguageToFrench() {
    localStorage.setItem("locale", "fr-FR");
    window.location.reload();
  }

  async fetchEnvironmentVariables() {
    if (this.environmentVariables.length === 0) {
      this.environmentVariables = await this.getContentfulEntriesAsObject("environmentVariables", null);
    }
  }

  async fetchBlog() {
    if (this.blog.length === 0) {
      const fetchedArticles = [];
      const entries = await this.client.getEntries({
        content_type: "blog",
        order: "fields.order",
        locale: localStorage.getItem('locale')
      })
      entries.items.forEach((item) => {
        item.fields['body'] = documentToHtmlString(item.fields['body'], options);
        fetchedArticles.push(item.fields);
      });
      this.blog = fetchedArticles;
    }
  }

  async fetchFaqs() {
    if (this.faqs.length === 0) {
      this.faqs = await this.getContentfulEntriesAsArray("faq", "fields.order");
    }
  }

  async fetchMilestones() {
    if (this.milestones.length === 0) {
      this.milestones = await this.getContentfulEntriesAsArray("milestone", "fields.order");
    }
  }

  async fetchTeam() {
    if (this.team.length === 0) {
      this.team = await this.getContentfulEntriesAsArray("team", "fields.order");
    }
  }

  async fetchNavBar() {
    this.navbar = await this.getContentfulEntriesAsObject("navigationMenu", null);
  }

  async fetchPlatformModal() {
    this.platformModal = await this.getContentfulEntriesAsObject("platformModal", null);
  }

  async fetchDevelopersModal() {
    this.developersModal = await this.getContentfulEntriesAsObject("developersModal", null);
  }

  async fetchFooter() {
    this.footer = await this.getContentfulEntriesAsObject("footer", null);
  }
  
  async fetchContactForm() {
    this.contactForm = await this.getContentfulEntriesAsObject("contactFormType", null);
  }
  
  async fetchRequestAccessForm() {
    this.requestAccessForm = await this.getContentfulEntriesAsObject("requestAccessModal", null);
  }
  
  // Get all necessary content for the Home Page
  async fetchHomePage() {
    this.homePage = await this.getContentfulEntriesAsObject("home-pg", null);
  }

  async fetchAboutPage() {
    this.homePage = await this.getContentfulEntriesAsObject("aboutUsPage", null);    
  }

  async fetchCareersPage() {
    this.careersPage = await this.getContentfulEntriesAsObject("careersPage", null);
  }

  async fetchContactUsPage() {
    this.contactUsPage = await this.getContentfulEntriesAsObject("contactUsPage", null);
  }

  async fetchFaqPage() {
    this.faqPage = await this.getContentfulEntriesAsObject("faqPage", null);
  }

  async fetchArticlePage() {
    this.articlePage = await this.getContentfulEntriesAsObject("articlePage", null);
  }

  async fetchDeveloperProgramPage() {
    this.developerProgramPage = await this.getContentfulEntriesAsObject("developerProgramPage", null);
  }

  async fetchInsightsPage() {
    this.insightsPage = await this.getContentfulEntriesAsObject("insightsPage", null);
  }

  async fetchPartnerProgramPage() {
    this.partnerProgramPage = await this.getContentfulEntriesAsObject("partnerProgramPage", null);
  }
  
  async fetchSupportedNetworksPage() {
    this.supportedNetworksPage = await this.getContentfulEntriesAsObject("supportedNetworksPage", null);
  }

  async fetchPrivacyPolicyPage() {
    // Two parts needed as maximum entries reached in first content model
    let firstPart, secondPart;
    firstPart = await this.getContentfulEntriesAsObject("privacyPolicyPage", null);
    secondPart = await this.getContentfulEntriesAsObject("privacyPolicyDisclosureSecurity", null);
    this.privacyPolicyPage = { ...firstPart, ...secondPart};    
  }

  async fetchTermsPage() {
    this.termsPage = await this.getContentfulEntriesAsObject("termsPage", null);
  }

  async fetchWhatIsMantlePage() {
    this.whatIsMantlePage = await this.getContentfulEntriesAsObject("whatIsMantlePageNew", null);
  }

  async fetchPrivacyPage() {
    this.privacyPage = await this.getContentfulEntriesAsObject("privacyPage", null);
  }
  
  async fetchCrossCloudStroagePage() {
    this.crossCloudStoragePage = await this.getContentfulEntriesAsObject("crossCloudStoragePage", null);
  }

  async fetchPricingPage() {
    this.pricingPage = await this.getContentfulEntriesAsObject("pricingPage", null);
  }

  async fetchAdvancedCyberSecurityPage() {
    this.advancedCyberSecurityPage = await this.getContentfulEntriesAsObject("advancedCybersecurityPage", null);
  }

  async fetchFutureProofPage() {
    this.futureProofPage = await this.getContentfulEntriesAsObject("futureProofPage", null);
  }

  async fetchPrivacyAndDataCompliancePage() {
    this.privacyAndDataCompliancePage = await this.getContentfulEntriesAsObject("privacyAndDataCompliancePage", null);
  }

  async fetchTrustedCompanies() {
    this.trustedCompanies = await this.getContentfulEntriesAsArray("trustedCompanies", null);
  }

  // Calls the getEntires function of Contentful to avoid repetition
  private async getContentfulEntriesAsArray(content_type: string, order: string) {
    const fetchedArray = [];
    const entries = await this.client.getEntries({
      content_type: content_type,
      order: order,
      locale: localStorage.getItem('locale')
    })
    entries.items.forEach((item) => {
      fetchedArray.push(item.fields);
    });
    return fetchedArray;
  }

  // Max nesting level set to 4
  private async getContentfulEntriesAsObject(content_type: string, order: string) {
    const entries = await this.client.getEntries({
      content_type: content_type,
      order: order,
      locale: localStorage.getItem('locale'),
      include: 4
    })
    return entries.items[0].fields;
  }
}
