<div
  class="poster__container"
  [ngClass]="{
    poster__background1: style === 1,
    poster__background2: style === 2,
    poster__background3: style === 3,
    poster__container1: size === 1,
    poster__container2: size === 2
  }"
>
  <h2 [ngClass]="size === 1 ? 'poster__header1' : 'poster__header2'">{{ title }}</h2>
  <p
    class="poster__category"
    [ngClass]="{
      'poster__category--1': style === 1,
      'poster__category--2': style === 2,
      'poster__category--3': style === 3
    }"
  >
    {{ category }}
  </p>
  <div class="poster__image-container">
    <img *ngIf="style === 1" class="poster__image" src="assets/images/shape-1.png" />
    <img *ngIf="style === 2" class="poster__image" src="assets/images/shape-2.png" />
    <img *ngIf="style === 3" class="poster__image" src="assets/images/shape-3.png" />
  </div>

  <button class="button__primary home__button3 poster__button" routerLink="/platform/what-is-mantle">
    {{actionBtnText}} <img src="assets/icons/arrow-diagonal-black.png" style="float:right;"/>
  </button>
  <!--
  <p
    class="poster__description"
    [ngClass]="{
      'poster__description--1': style === 1,
      'poster__description--2': style === 2,
      'poster__description--3': style === 3
    }"
  >
    {{ description }}
  </p>
  -->
  <!-- TO RE-ENABLE ONCE LINKS TO THE DIFFERENT POSTERS ARE FUNCTIONAL -->
  <!-- <a class="poster__link" [routerLink]="link">{{ actionBtnText }}</a> -->
</div>
