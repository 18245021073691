<div class="container">
  <div class="section mb-5">
    <h2 class="trusted-companies__header mb-5">{{heading}}</h2>
    <div class="row text-center">
      <div *ngFor="let logo of companyLogos" class="col-l-3 col-md-3 mt-5">
        <a [href]="logo.fields.description" target="_blank">
          <img class="trusted-companies__image mb-1" [src]="logo.fields.file.url" />
          <p class="trusted-companies__title">{{ logo.fields.title }}</p>
        </a>
      </div>
    </div>
  </div>
</div>
