import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequestAccessComponent } from '../modals/request-access/request-access.component';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild('footerAccordion', { static: true }) accordion: NgbAccordion;
  routeSubscription: Subscription;
  isPrimaryContent = true;
  frenchSet = localStorage.getItem('locale') === "fr-FR";
  isOnFaq = false;
  // Check if application is running in Dev or in Prod
  isDevMode = isDevMode();
  isOnWhatIsMantle = false;

  constructor(private modalService: NgbModal, private router: Router, public contentfulService: ContentfulService) {}

  ngOnInit(): void {
    this.contentfulService.fetchFooter();
    this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const splitURL = this.router.url.split('?')[0];
      this.isPrimaryContent = splitURL !== '/developers/supported-networks';
      this.isOnFaq = splitURL === '/company/faq';
      this.isOnWhatIsMantle = splitURL.split('#')[0] === '/platform/what-is-mantle'
    });
  }

  onClick(route: string) {
    this.accordion.collapseAll();
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }

  scroll(id){
    if(id==='top'){
      window.scrollTo({top:0, left:0, behavior: 'smooth'})
    }
    else{
      document.getElementById(id).scrollIntoView({behavior:'smooth'})
    }
  }

  openAccessModal() {
    const modalRef = this.modalService.open(RequestAccessComponent);
    modalRef.result
      .then((result) => {
        // Result is either 'success' or 'error'
        if (result === 'error') {
          console.log('TODO: ROUTE TO ERROR PAGE');
        }
      })
      .catch((dismissed) => {
        console.log('Request access form escaped');
      });
  } 
}
