import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { NavExtendedComponent } from '../../modals/nav-extended/nav-extended.component';
import { RequestAccessComponent } from '../../modals/request-access/request-access.component';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  routeSubscription: Subscription;
  isPrimaryHeader = true; // Light header
  showMobileMenu = false;
  // Check if application is running in Dev or in Prod
  isDevMode = isDevMode();

  // Light implementation of language feature, only works with 2 (default English)
  otherLanguage = localStorage.getItem('locale') === "fr-FR" ? "EN" : "FR";
  isWebsiteInEnglish = localStorage.getItem('locale') === "en-US" || localStorage.getItem('locale') === null;

  constructor(private modalService: NgbModal, private router: Router, public contentfulService: ContentfulService) {}

  ngOnInit(): void {
    this.contentfulService.fetchNavBar();
    this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const splitURL = this.router.url.split('?')[0];
      this.isPrimaryHeader = splitURL !== '/developers/insights' &&
        splitURL !== '/developers/supported-networks' &&
        splitURL !== '/platform/pricing';
    });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  switchLanguage() {
    if (this.isWebsiteInEnglish) {
      this.contentfulService.changeLanguageToFrench();
      this.isWebsiteInEnglish = false;
    } else {
      this.contentfulService.changeLanguageToEnglish();
      this.isWebsiteInEnglish = true;
    }
  }

  // Used for Old Content in Prod
  onClick(route: string) {
    this.showMobileMenu = false;
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }

  // Used for New Content in Develop
  openMenuModal(section: string): void {
    const modalRef = this.modalService.open(NavExtendedComponent);
    modalRef.componentInstance.extendedMenuType = section;
    modalRef.result
      .then((result) => {
        // Result is either 'close' or 'back'
        if (result === 'close') {
          this.showMobileMenu = false;
        }
      })
      .catch((dismissed) => {
        this.showMobileMenu = false;
      });
  }

  openAccessModal() {
    const modalRef = this.modalService.open(RequestAccessComponent);
    modalRef.result
      .then((result) => {
        // Result is either 'success' or 'error'
        if (result === 'error') {
          console.log('TODO: ROUTE TO ERROR PAGE');
        }
      })
      .catch((dismissed) => {
        console.log('Request access form escaped');
      });
  }

}
