import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  activeTestimonial = 0;
  @Input() header: string;
  @Input() subHeader: string;
  @Input() customerTestimonials: any[];
  
  constructor() {}

  ngOnInit(): void {
  }

  onPrevious() {
    if (this.activeTestimonial !== 0) {
      this.activeTestimonial -= 1;
    }
  }

  onNext() {
    if (this.activeTestimonial !== this.customerTestimonials.length - 1) {
      this.activeTestimonial += 1;
    }
  }
}
