<div class="access__container">

  <div class="d-flex justify-content-end">
    <img class="justify-content-end" (click)="closeForm()" src="assets/icons/close.png" />
  </div>

  <div class="d-flex mb-5 justify-content-center">
    <img class="justify-content-start" src="assets/icons/logo-stacked.png" />
  </div>

  <form [formGroup]="form" name="requestAccess" method="post" (ngSubmit)="onSubmit()">
    <input type="hidden" name="form-name" value="requestAccess" />

    <div class="row">
      <div class="col-12 col-sm-6">
        <input
          class="access__input-text"
          type="text"
          name="firstName"
          formControlName="firstName"
          placeholder={{contentfulService.requestAccessForm.firstNamePlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-6">
        <input
          class="access__input-text"
          type="text"
          name="lastName"
          formControlName="lastName"
          placeholder={{contentfulService.requestAccessForm.lastNamePlaceholder}}
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <input
          class="access__input-text"
          type="text"
          name="company"
          formControlName="company"
          placeholder={{contentfulService.requestAccessForm.companyPlaceholder}}
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6">
        <input class="access__input-text" type="text" name="title" formControlName="title" placeholder={{contentfulService.requestAccessForm.titlePlaceholder}} required />
      </div>
      <div class="col-12 col-sm-6">
        <select class="access__input-select" name="role" formControlName="role">
          <option value="" disabled>{{contentfulService.requestAccessForm.roleSelectTitle}}</option>
          <option value="architect">{{contentfulService.requestAccessForm.architectRole}}</option>
          <option value="developer">{{contentfulService.requestAccessForm.developerRole}}</option>
          <option value="executive">{{contentfulService.requestAccessForm.executiveRole}}</option>
          <option value="manager">{{contentfulService.requestAccessForm.managerRole}}</option>
          <option value="other">{{contentfulService.requestAccessForm.otherRole}}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <input
          class="access__input-text"
          type="email"
          name="email"
          formControlName="email"
          placeholder={{contentfulService.requestAccessForm.emailPlaceholder}}
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <textarea
          class="access__input-textarea"
          name="message"
          formControlName="message"
          placeholder={{contentfulService.requestAccessForm.messagePlaceholder}}
          required
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 m-auto">
        <button class="button__primary" type="submit">{{contentfulService.requestAccessForm.buttonText}}</button>
      </div>
    </div>
  </form>
</div>
