import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ContentfulService } from '../../services/contentful/contentful.service';

@Component({
  selector: 'app-codebox',
  templateUrl:'./codebox.component.html',
  styleUrls: ['./codebox.component.scss'],
})
export class CodeboxComponent implements OnInit {
  
  @Input() content: string;
  @Input() caption: string;
  @Input() comment: string;
  @Input() value: string;
  isMobile: boolean;

  constructor(private renderer: Renderer2, public contentfulService: ContentfulService) {
  }
 

  async ngOnInit() {
    this.contentfulService.fetchHomePage();
    this.content=`  /* This Javascript code SandBox lets you write some
     string on the Waves blockchain network */
    
    function postOnWaves(stringToPost) {
      post(stringToPost)
    }
    
// Lets call the function we just defined!!
    postOnWaves("Hello")`;
    this.isMobile = window.innerWidth <= 500;
  }

  onClickMe(e) {
    document.getElementById("link").innerHTML="";
    let post=` 
      async function post(p){
        localStorage.removeItem("success");
        var blob = new Blob([p]);
        var formData = new FormData();
        formData.append(\"file\", blob);
        document.getElementById("cover-spin").hidden = false;
        var res = await fetch(\"https://develop.api.mantle.technology/gateway/receipt/\",{
          method: \"post\",
          headers : {
            \"x-api-key\" : \"jOdG4Pvv/9oQePThidn9BKfQPL3bS+F1z6VB5VNUDU0=\"
          },
            body: formData
          })
          if(res.status === 201){
            localStorage.setItem("success", "true");
            var resJson = await res.json();
            const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
            await sleep(4000);
            document.getElementById("cover-spin").hidden = true;
            var element = document.getElementById("link");
            element.innerHTML="";
            document.getElementById("link").hidden = false;
            var a = document.createElement('a');
            var linkText = document.createTextNode("${this.contentfulService.homePage.popUpSuccess}");
            a.appendChild(linkText);
            a.title = "See waves block explorer";
            a.href = "https://testnet.wavesexplorer.com/address/"+resJson.pointer.substring(3)+"/tx";
            a.id="toastMessageId"
            a.style="color: black"
            a.target="_blank"
            element.appendChild(a);
          }
          else{
            localStorage.setItem("success", "false");
            var tag = document.createElement("toast-container");
            var element = document.getElementById("link");
            element.appendChild(tag);
        
            var p = document.createElement('p');
            var errorText = document.createTextNode("Something went wrong, try another time!");
            p.appendChild(errorText);
            p.title = "Your code sample did not compile, try something else!";
            p.id="toastMessageId"
            element.appendChild(p);
            document.getElementById("cover-spin").hidden = true;
            document.getElementById("link").hidden = false;
            setTimeout(x => {
              document.getElementById("link").hidden = true;
            }, 10000);
          }
      }`
    var myObj = document.getElementById("toastMessageId")

    if (myObj != null) {
      myObj.remove();
    }
    // checks if the user's code will compile
    window.onerror = async function (message, source, line, col, error) {
      const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
      await sleep(1000);
      if(localStorage.getItem("success") === "true"){
        localStorage.removeItem("success");
        return;
      }
      var tag = document.createElement("toast-container");
      var element = document.getElementById("link");
      element.appendChild(tag);
  
      var p = document.createElement('p');
      var errorText = document.createTextNode(error.name+": "+error.message);

      p.appendChild(errorText);
      p.title = "Your code sample did not compile, try something else!";
      p.id="toastMessageId"
      element.appendChild(p);
      document.getElementById("cover-spin").hidden = true;
      document.getElementById("link").hidden = false;
      setTimeout(x => {
        document.getElementById("link").hidden = true;
      }, 10000);
    }
    let myScript = this.renderer.createElement('script');
    myScript.type = `text/javascript`;
    myScript.text = `${e+post}`;
    this.renderer.appendChild(document.body, myScript);
  }
}
