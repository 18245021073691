<div>
  <ngx-codemirror 
  [(ngModel)]="content"
  [options]="{
    lineNumbers: true,
    theme: 'material',
    mode: 'markdown'
    }" #codebox>
  </ngx-codemirror>

  <div class="d-flex">
    <div class="codebox__image-footer--left">
      <div class="box">
        <a class="codebox__button" href="#help">{{contentfulService.homePage.imageFooterLeft }}</a>
      </div>
    </div>

    <div (click)="onClickMe(codebox.value);" class="codebox__image-footer--right">
      <div id="cover-spin" hidden></div>
      <img src="assets/icons/play.png" />
      <p *ngIf="!isMobile">{{contentfulService.homePage.imageFooterRight}}</p>
    </div>
  </div>
  <div id="help" class="codebox__overlay">
    <div class="codebox__popup">
      <a class="close" href="#!"><img src="assets/icons/close.png"></a>
      <h2>Mantle SDK Demo</h2>
      <div class="content">
        {{contentfulService.homePage.helpMessage}}
      </div>
    </div>
  </div>
</div>
  
  
