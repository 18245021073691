import { Component, Input, OnInit } from '@angular/core';
import { ContentfulService } from '../../../services/contentful/contentful.service';

@Component({
  selector: 'app-trusted-companies',
  templateUrl: './trusted-companies.component.html',
  styleUrls: ['./trusted-companies.component.scss']
})
export class TrustedCompaniesComponent implements OnInit {
  @Input() heading: string;
  @Input() companyLogos: any[];

  constructor(public contentfulService: ContentfulService) {}

  ngOnInit(): void {
    this.contentfulService.fetchTrustedCompanies();
  }

}
