<!-- Main navigation horizontal header -->
<div
  class="nav-bar__header"
  [ngClass]="{
    'nav-bar__secondary-background': !isPrimaryHeader && !showMobileMenu,
    'nav-bar__header-shadow': isPrimaryHeader || showMobileMenu
  }"
>
  <ul class="d-flex">
    <!-- MANTLE LOGO -->
    <li routerLink="/">
      <img class="nav-bar__logo"
        [src]="isPrimaryHeader || showMobileMenu ? 'assets/icons/logo-maroon.png' : 'assets/icons/logo-white.png'" />
    </li>
    <!-- HOME BUTTON -->
    <li *ngIf="!isDevMode" class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }"
      routerLink="/">
      {{contentfulService.navbar.home}}
    </li>
    <li *ngIf="isDevMode" class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }"
      routerLink="/">
      {{contentfulService.navbar.home}}
    </li>
    <!-- PLATFORM BUTTON -->
    <li class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }"
      routerLink="/platform/what-is-mantle">
      {{contentfulService.navbar.platform}}
    </li>
    <!-- PRICING BUTTON -->
    <li class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }" routerLink="/platform/pricing">Pricing</li>
  
    <!-- DEVELOPERS BUTTON -->
    <li class="nav-bar__item">
          <a class="nav-bar__link" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }" href="https://developer.mantleblockchain.com/docs/getting-started">
        {{contentfulService.navbar.developers}}
      </a>
    </li>
    <!-- ABOUT BUTTON -->
    <li class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }" routerLink="/company/about">
      {{contentfulService.navbar.about}}
    </li>
     </ul>
  <!-- RIGHT SIDE OPTIONS -->
  <ul class="d-flex">
     <!-- LANGUAGE BUTTON -->
     <li class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }" (click)="switchLanguage()">
      {{otherLanguage}}
    </li>
    <!-- LOGIN BUTTON -->
    <li class="nav-bar__item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }">
      <a class="nav-bar__link" href="https://develop.app.mantle.technology/login" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }">
        {{contentfulService.navbar.login}}
      </a>
    </li>
    <!-- REQUEST ACCESS BUTTON -->
    <li class="nav-bar__button">
      <button class="button__primary" (click)="openAccessModal()">{{contentfulService.navbar.access}}</button>
    </li>
    <!-- BURGER MENU TOGGLE FOR MOBILE USERS -->
    <li class="nav-bar__menu-toggle" (click)="toggleMobileMenu()">
      <img *ngIf="showMobileMenu" src="assets/icons/close.png" />
      <img *ngIf="!showMobileMenu"
        [src]="isPrimaryHeader ? 'assets/icons/hamburger-menu-maroon.png' : 'assets/icons/hamburger-menu-white.png'" />
    </li>
  </ul>
</div>

<!-- Mobile dropdown menu -->
<div *ngIf="showMobileMenu" class="nav-bar__menu-container">
  <ul>
    <!-- PLATFORM BUTTON -->
    <li class="nav-bar__menu-item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }"
      (click)="onClick('/platform/what-is-mantle')">
      <p>{{contentfulService.navbar.platform}}</p>
    </li>
    <!-- DEVELOPERS BUTTON -->
    <li class="nav-bar__menu-item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }">
      <p><a class="nav-bar__link" href="https://developer.mantleblockchain.com/docs">{{contentfulService.navbar.developers}}</a></p>
    </li>
    <!-- PRICING BUTTON -->
    <li class="nav-bar__menu-item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }"
    (click)="onClick('/platform/pricing')">
      {{contentfulService.navbar.pricing}}
    </li>
    <!-- ABOUT BUTTON -->
    <li class="nav-bar__menu-item" [ngClass]="{ 'nav-bar__secondary-color': !isPrimaryHeader }" 
    (click)="onClick('/company/about')">
      {{contentfulService.navbar.about}}
    </li>
  </ul>
    <!-- REQUEST ACCESS BUTTON -->
  <div class="nav-bar__menu-button">
    <button class="button__primary d-flex justify-content-between" (click)="openAccessModal()">
      {{contentfulService.navbar.access}}<img src="assets/icons/arrow-diagonal-black.png" />
    </button>
  </div>
</div>