<div class="contact-form" [ngClass]="{ 'contact-form__background--dark': isDarkBackground }">
  <div class="container contact-form__container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h3
          class="contact-form__header"
          [ngClass]="isDarkBackground ? 'contact-form__header--light' : 'contact-form__header--dark'"
        >
          {{ title }}
        </h3>
        <p [ngClass]="isDarkBackground ? 'contact-form__subheader--light' : 'contact-form__subheader--dark'">
          {{ subtitle }}
        </p>
      </div>

      <div [ngSwitch]="type" class="col-12 col-md-6">
        <ng-container *ngSwitchCase="'account'">
          <ng-container *ngTemplateOutlet="account"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'partner'">
          <ng-container *ngTemplateOutlet="partner"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'newsletter'">
          <ng-container *ngTemplateOutlet="newsletter"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'contactUs'">
          <ng-container *ngTemplateOutlet="contact"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #account>
  <form [formGroup]="accountForm" name="account" method="post" (ngSubmit)="onSubmit()">
    <input type="hidden" name="form-name" value="account" />

    <div class="row">
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="firstName"
          formControlName="firstName"
          placeholder={{contentfulService.contactForm.firstNamePlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="lastName"
          formControlName="lastName"
          placeholder={{contentfulService.contactForm.lastNamePlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="email"
          name="email"
          formControlName="email"
          placeholder={{contentfulService.contactForm.emailPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="company"
          formControlName="company"
          placeholder={{contentfulService.contactForm.companyPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textarea
          class="contact-form__input-text contact-form__input-text--dark contact-form__input-textarea"
          name="message"
          formControlName="message"
          placeholder={{contentfulService.contactForm.messagePlaceholder}}
          required
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3 d-flex">
        <div>
          <input class="contact-form__input-radio mr-3" type="radio" />
        </div>
        <label>
            {{contentfulService.contactForm.communicationsLabel}}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3 d-flex">
        <div>
          <input class="contact-form__input-radio mr-3" type="radio" />
        </div>
        <label>{{contentfulService.contactForm.agreementLabel}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="button__primary" type="submit">{{contentfulService.contactForm.accountButtonText}}</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #partner>
  <form [formGroup]="partnerForm" name="partner" method="post" (ngSubmit)="onSubmit()">
    <input type="hidden" name="form-name" value="partner" />

    <div class="row">
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="firstName"
          formControlName="firstName"
          placeholder={{contentfulService.contactForm.firstNamePlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="lastName"
          formControlName="lastName"
          placeholder={{contentfulService.contactForm.lastNamePlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="email"
          name="email"
          formControlName="email"
          placeholder={{contentfulService.contactForm.emailPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-7">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="job"
          formControlName="job"
          placeholder={{contentfulService.contactForm.jobPlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-5">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="company"
          formControlName="company"
          placeholder={{contentfulService.contactForm.companyPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="phone"
          name="phone"
          formControlName="phone"
          placeholder={{contentfulService.contactForm.phoneNumberPlaceholder}}
        />
      </div>
      <div class="col-12 col-sm-4">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="country"
          formControlName="country"
          placeholder={{contentfulService.contactForm.countryPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <select
          class="contact-form__input-text contact-form__input-text--dark contact-form__input-select"
          name="partnership"
          formControlName="partnership"
        >
          <option value="" disabled>{{contentfulService.contactForm.partnershipTypeTitle}}</option>
          <option value="architect">A</option>
          <option value="developer">B</option>
          <option value="executive">C</option>
          <option value="manager">D</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="button__primary" type="submit">{{contentfulService.contactForm.partnerButtonText}}</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #newsletter>
  <form [formGroup]="newsletterForm" name="newslettter" method="post" (ngSubmit)="onSubmit()">
    <input type="hidden" name="form-name" value="newslettter" />

    <div class="row">
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="firstName"
          formControlName="firstName"
          placeholder={{contentfulService.contactForm.firstNamePlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="text"
          name="lastName"
          formControlName="lastName"
          placeholder={{contentfulService.contactForm.lastNamePlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--dark"
          type="email"
          name="email"
          formControlName="email"
          placeholder={{contentfulService.contactForm.emailPlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="button__primary" type="submit">{{contentfulService.contactForm.newsletterButtonText}}</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #contact>
  <form [formGroup]="contactUsForm" name="contactUs" method="post" (ngSubmit)="onSubmit()">
    <input type="hidden" name="form-name" value="contactUs" />

    <div class="row">
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--light"
          type="text"
          name="firstName"
          formControlName="firstName"
          placeholder={{contentfulService.contactForm.firstNamePlaceholder}}
          required
        />
      </div>
      <div class="col-12 col-sm-6">
        <input
          class="contact-form__input-text contact-form__input-text--light"
          type="text"
          name="lastName"
          formControlName="lastName"
          placeholder={{contentfulService.contactForm.lastNamePlaceholder}}
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--light"
          type="text"
          name="address"
          formControlName="email"
          placeholder="Address of Residence"
          required
        />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <input
          class="contact-form__input-text contact-form__input-text--light"
          type="text"
          name="address"
          formControlName="address"
          placeholder="Address of Residence"
          required
        />
      </div>
    </div> -->
    <div class="row">
    <div class="col-12 col-sm-6">
      <label class="contact-form" style="margin-right: 20px; margin-bottom: 10px;">I am an accredited investor</label>
      <input
        class="contact-form"
        type="checkbox"
        name="company"
        style="height: 20px; width: 20px;"
        formControlName="company"
        placeholder="I am an accredited investor"
        required
      />
    </div>
    </div>
    <div class="row">
      <div class="col-12">
        <input
        type="number"
        min="25000"
        max="250000"
        class="contact-form__input-text contact-form__input-text--light"
        name="message"
        formControlName="message"
        placeholder="I want to invest this amount in $CAD (minimum $25k)"
        required
      />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="button__primary" type="submit">{{contentfulService.contactForm.contactButtonText}}</button>
      </div>
    </div>
  </form>
</ng-template>
