<!-- Modal 1 - Platform -->
<ng-container *ngIf="extendedMenuType === 'platform'; else developers">
  <div class="platform__header">
    <div class="d-flex" (click)="onBack()">
      <img class="arrow" src="assets/icons/arrow-right-maroon.png" />
      <p>{{contentfulService.platformModal.title}}</p>
    </div>
    <div (click)="onClose()">
      <img src="assets/icons/close.png" />
    </div>
  </div>

  <div class="platform__container">
    <div class="row">
      <div class="col-12 col-md-6 mt-5">
        <p class="platform__heading">{{contentfulService.platformModal.heading}}</p>
        <p class="platform__subheading">{{contentfulService.platformModal.subHeading}}</p>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex" (click)="onRoute('/platform/what-is-mantle')">
              <p class="platform__link">{{contentfulService.platformModal.aboutLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/what-makes-us-different')">
              <p class="platform__link">{{contentfulService.platformModal.differenceLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 mt-5">
        <div class="d-flex platform__clickable" (click)="onRoute('/platform/privacy')">
          <div>
            <p class="platform__heading platform__heading-link">{{contentfulService.platformModal.dataPrivacySectionTitle}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            <p class="platform__subheading">{{contentfulService.platformModal.dataPrivacySectionDesc}}</p>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/privacy/cross-cloud-storage')">
              <p class="platform__link">{{contentfulService.platformModal.crossCloudStorageLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/privacy/distributed-identity')">
              <p class="platform__link">{{contentfulService.platformModal.distributedIdentityLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 mt-5">
        <div class="d-flex platform__clickable" (click)="onRoute('/platform/auditing')">
          <div>
            <p class="platform__heading platform__heading-link">{{contentfulService.platformModal.dataAuditingSectionTitle}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            <p class="platform__subheading">{{contentfulService.platformModal.dataAuditingSectionDesc}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/auditing/agreement-validation')">
              <p class="platform__link">{{contentfulService.platformModal.agrementValidationLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/auditing/data-provenance')">
              <p class="platform__link">{{contentfulService.platformModal.dataProvenanceLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
        <div class="row mt-md-4">
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/auditing/audit-portal')">
              <p class="platform__link">{{contentfulService.platformModal.auditPortalLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 mt-5">
        <div class="d-flex platform__clickable" (click)="onRoute('/platform/case-studies')">
          <div>
            <p class="platform__heading platform__heading-link">{{contentfulService.platformModal.caseStudiesLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            <p class="platform__subheading">{{contentfulService.platformModal.caseStudiesDesc}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/case-studies/legal-tech')">
              <p class="platform__link">{{contentfulService.platformModal.legalTechLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/case-studies/clean-tech')">
              <p class="platform__link">{{contentfulService.platformModal.cleanTechLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
        <div class="row mt-md-4">
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/case-studies/gaming')">
              <p class="platform__link">{{contentfulService.platformModal.gamingLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex d-md-block">
            <div>
              <img class="platform__icon" src="assets/icons/network.png" />
            </div>
            <div class="d-flex developers__clickable" (click)="onRoute('/platform/case-studies/kyc')">
              <p class="platform__link">{{contentfulService.platformModal.kycLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Modal 2 - Developers -->
<ng-template #developers>
  <div class="developers__header">
    <div class="d-flex" (click)="onBack()">
      <img src="assets/icons/arrow-left-maroon.png" />
      <p>{{contentfulService.developersModal.title}}</p>
    </div>
    <div (click)="onClose()">
      <img src="assets/icons/close.png" />
    </div>
  </div>

  <div class="developers__container">
    <div class="row">
      <div class="col-12 col-md-6 mt-5">
        <p class="developers__heading">{{contentfulService.developersModal.ressourcesHeading}}</p>
        <div class="d-flex d-md-block">
          <div>
            <img class="platform__icon" src="assets/icons/network.png" />
          </div>
          <div class="d-flex developers__clickable" (click)="onRoute('/platform/privacy/cross-cloud-storage')">
            <div>
              <p class="developers__link">{{contentfulService.developersModal.documentationLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
              <p class="developers__subheading">{{contentfulService.developersModal.developersDescription}}</p>
            </div>
          </div>
        </div>
        <div class="d-flex d-md-block mt-4 mt-md-5">
          <div>
            <img class="platform__icon" src="assets/icons/network.png" />
          </div>
          <div class="d-flex developers__clickable" (click)="onRoute('/platform/privacy/distributed-identity')">
            <div>
              <p class="developers__link">{{contentfulService.developersModal.quickstartLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
              <p class="developers__subheading">{{contentfulService.developersModal.quickstartDescription}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 mt-5">
        <div>
          <p class="developers__heading">{{contentfulService.developersModal.programsHeading}}</p>
          <div class="d-flex developers__clickable" (click)="onRoute('/developers/developer-program')">
            <p class="developers__link">{{contentfulService.developersModal.developerProgramLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>

          </div>
          <div class="d-flex mt-3 developers__clickable" (click)="onRoute('/developers/partner-program')">
            <p class="developers__link">{{contentfulService.developersModal.partnerProgramLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
            <div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <p class="developers__heading">{{contentfulService.developersModal.moreHeading}}</p>
          <div class="d-flex developers__clickable" (click)="onRoute('/developers/insights')">
            <div>
              <p class="developers__link">{{contentfulService.developersModal.insightsLink}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
              <p class="developers__subheading">{{contentfulService.developersModal.insightsDesc}}</p>
            </div>
          </div>
          <div class="d-flex mt-4 developers__clickable" (click)="onRoute('/developers/supported-networks')">
            <div>
              <p class="developers__link">{{contentfulService.developersModal.networksLinks}}<img class="arrow" src="assets/icons/arrow-right-maroon.png" /></p>
              <p class="developers__subheading">{{contentfulService.developersModal.networksDesc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
