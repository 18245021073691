<div class="header__backdrop1" [style.padding-top]="isHomePage? '0px':'130px'" [style.margin-bottom]="isHomePage? '50px':'100px'">
  <div [ngClass]="{ 'header__backdrop2' : isHomePage, 'header__backdrop3' : !isHomePage }">
    <div class="container">
      <label *ngIf="headingLabel" class="header__heading-label">{{ headingLabel }}</label>
      <h1 class="header__heading"
        [ngClass]="{ 'header__heading--top': !headingLabel, 'header__heading--bottom': !subheading }">{{ heading }}</h1>
      <p *ngIf="subheading" class="header__subheading">{{ subheading }}</p>
    </div>

    <!-- Block image only displays on mobile -->
    <div>
      <img class="header__heading-image" src="assets/backgrounds/athens.png" />
    </div>

    <div class="container" *ngIf="showBreadcrumb">
      <p class="header__breadcrumbs">
        <span class="header__breadcrumb--brown" [routerLink]="'/' + routeSegments[0]">{{ routeSegments[0] | titlecase
          }}</span>
        <span>
          <span> /</span>
          <span [ngClass]="{ 'header__breadcrumb--brown': routeSegments.length === 3 }"
            [routerLink]="'/' + routeSegments[0] + '/' + routeSegments[1]">
            {{ routeSegments[1] | titlecase }}</span>
        </span>
        <span *ngIf="routeSegments.length > 2"
          [routerLink]="'/' + routeSegments[0] + '/' + routeSegments[1] + '/' + routeSegments[2]">
          / {{ routeSegments[2] | titlecase }}</span>
      </p>
    </div>
  </div>
</div>